div.jsoneditor .jsoneditor-search input {
  height: auto;
  border: inherit
}

  div.jsoneditor .jsoneditor-search input:focus {
    border: none !important;
    box-shadow: none !important
  }

div.jsoneditor table {
  border-collapse: collapse;
  width: auto
}

div.jsoneditor td, div.jsoneditor th {
  padding: 0;
  display: table-cell;
  text-align: left;
  vertical-align: inherit;
  border-radius: inherit
}

div.jsoneditor-field, div.jsoneditor-readonly, div.jsoneditor-value {
  border: 1px solid transparent;
  min-height: 16px;
  min-width: 32px;
  padding: 4px;
  font-size:20;
  font-weight:800;
  margin: 3px;
  word-wrap: break-word;
  float: left
}

  div.jsoneditor-field p, div.jsoneditor-value p {
    margin: 0
  }

div.jsoneditor-value {
  word-break: break-word
}

div.jsoneditor-readonly {
  min-width: 16px;
  color: gray
}

div.jsoneditor-empty {
  border-color: #d3d3d3;
  border-style: dashed;
  border-radius: 2px
}

div.jsoneditor-field.jsoneditor-empty::after, div.jsoneditor-value.jsoneditor-empty::after {
  pointer-events: none;
  color: #d3d3d3;
  font-size: 8pt
}

div.jsoneditor-field.jsoneditor-empty::after {
  content: "field"
}

div.jsoneditor-value.jsoneditor-empty::after {
  content: "value"
}

a.jsoneditor-value.jsoneditor-url, div.jsoneditor-value.jsoneditor-url {
  color: green;
  text-decoration: underline
}

a.jsoneditor-value.jsoneditor-url {
  display: inline-block;
  padding: 2px;
  margin: 2px
}

  a.jsoneditor-value.jsoneditor-url:focus, a.jsoneditor-value.jsoneditor-url:hover {
    color: #ee422e
  }

div.jsoneditor td.jsoneditor-separator {
  padding: 8px 0;
  vertical-align: top;
  color: gray
}

div.jsoneditor-field.jsoneditor-highlight, div.jsoneditor-field[contenteditable=true]:focus, div.jsoneditor-field[contenteditable=true]:hover, div.jsoneditor-value.jsoneditor-highlight, div.jsoneditor-value[contenteditable=true]:focus, div.jsoneditor-value[contenteditable=true]:hover {
  background-color: #ffffab;
  border: 1px solid #ff0;
  border-radius: 2px
}

div.jsoneditor-field.jsoneditor-highlight-active, div.jsoneditor-field.jsoneditor-highlight-active:focus, div.jsoneditor-field.jsoneditor-highlight-active:hover, div.jsoneditor-value.jsoneditor-highlight-active, div.jsoneditor-value.jsoneditor-highlight-active:focus, div.jsoneditor-value.jsoneditor-highlight-active:hover {
  background-color: #fe0;
  border: 1px solid #ffc700;
  border-radius: 2px
}

div.jsoneditor-value.jsoneditor-string {
  color: green
}

div.jsoneditor-value.jsoneditor-array, div.jsoneditor-value.jsoneditor-object {
  min-width: 16px;
  color: grey
}

div.jsoneditor-value.jsoneditor-number {
  color: #ee422e
}

div.jsoneditor-value.jsoneditor-boolean {
  color: #ff8c00
}

div.jsoneditor-value.jsoneditor-null {
  color: #004ed0
}

div.jsoneditor-value.jsoneditor-invalid {
  color: #000
}

div.jsoneditor-tree button {
  width: 24px;
  height: 22px;
  padding: 0;
  margin: 8px;
  border: none;
  cursor: pointer;
  margin-top: 3px;
  font-weight:600;
  /*background: transparent url(../img/jsoneditor-icons.svg)*/
}

div.jsoneditor-mode-form tr.jsoneditor-expandable td.jsoneditor-tree, div.jsoneditor-mode-view tr.jsoneditor-expandable td.jsoneditor-tree {
  cursor: pointer
}

div.jsoneditor-tree button.jsoneditor-collapsed::before {
  /*background-position: 0 -48px*/
  content: "+";
  font-size: 22px;
}

div.jsoneditor-tree button.jsoneditor-expanded::before {
  /*background-position: 0 -72px*/
  content: "-";
  font-size: 22px;
}

div.jsoneditor-tree button.jsoneditor-contextmenu {
  background-position: -48px -72px
}

  div.jsoneditor-tree button.jsoneditor-contextmenu.jsoneditor-selected, div.jsoneditor-tree button.jsoneditor-contextmenu:focus, div.jsoneditor-tree button.jsoneditor-contextmenu:hover, tr.jsoneditor-selected.jsoneditor-first button.jsoneditor-contextmenu {
    background-position: -48px -48px
  }

div.jsoneditor-tree :focus {
  outline: 0
}

div.jsoneditor-tree button:focus {
  background-color: #f5f5f5;
  outline: #e5e5e5 solid 1px
}

div.jsoneditor-tree button.jsoneditor-invisible {
  visibility: hidden;
  background: 0 0
}

div.jsoneditor {
  color: #1a1a1a;
  /*border: 1px solid #3883fa;*/
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0;
  line-height: 100%
}

div.jsoneditor-tree table.jsoneditor-tree {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin: 0;
}

div.jsoneditor-outer {
  position: static;
  width: 100%;
  height: 100%;
  margin: -35px 0 0 0;
  padding: 35px 0 0 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.ace-jsoneditor, textarea.jsoneditor-text {
  min-height: 150px
}

div.jsoneditor-tree {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto
}

textarea.jsoneditor-text {
  width: 100%;
  height: 100%;
  margin: 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline-width: 0;
  border: none;
  background-color: #fff;
  resize: none
}

tr.jsoneditor-highlight, tr.jsoneditor-selected {
  background-color: #e6e6e6
}

  tr.jsoneditor-selected button.jsoneditor-contextmenu, tr.jsoneditor-selected button.jsoneditor-dragarea {
    visibility: hidden
  }

  tr.jsoneditor-selected.jsoneditor-first button.jsoneditor-contextmenu, tr.jsoneditor-selected.jsoneditor-first button.jsoneditor-dragarea {
    visibility: visible
  }

div.jsoneditor-tree button.jsoneditor-dragarea {
  /*background: url(../img/jsoneditor-icons.svg) -72px -72px;*/
  cursor: move
}

  div.jsoneditor-tree button.jsoneditor-dragarea:focus, div.jsoneditor-tree button.jsoneditor-dragarea:hover, tr.jsoneditor-selected.jsoneditor-first button.jsoneditor-dragarea {
    background-position: -72px -48px
  }

div.jsoneditor td, div.jsoneditor th, div.jsoneditor tr {
  padding: 0;
  margin: 0
}

div.jsoneditor td {
  vertical-align: top
}

  div.jsoneditor td.jsoneditor-tree {
    vertical-align: top
  }

.jsoneditor-schema-error, div.jsoneditor td, div.jsoneditor textarea, div.jsoneditor th, div.jsoneditor-field, div.jsoneditor-value {
  font-family: droid sans mono,consolas,monospace,courier new,courier,sans-serif;
  /*font-size: 10pt;*/
  font-size:large;
  font-weight:600;
  color: #1a1a1a;
}

.jsoneditor-schema-error {
  cursor: default;
  display: inline-block;
  height: 24px;
  line-height: 24px;
  position: relative;
  text-align: center;
  width: 24px
}

div.jsoneditor-tree .jsoneditor-schema-error {
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0 4px 0 0;
  /*background: url(../img/jsoneditor-icons.svg) -168px -48px*/
}

.jsoneditor-schema-error .jsoneditor-popover {
  background-color: #4c4c4c;
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(0,0,0,.4);
  color: #fff;
  display: none;
  padding: 7px 10px;
  position: absolute;
  width: 200px;
  z-index: 4
}

  .jsoneditor-schema-error .jsoneditor-popover.jsoneditor-above {
    bottom: 32px;
    left: -98px
  }

  .jsoneditor-schema-error .jsoneditor-popover.jsoneditor-below {
    top: 32px;
    left: -98px
  }

  .jsoneditor-schema-error .jsoneditor-popover.jsoneditor-left {
    top: -7px;
    right: 32px
  }

  .jsoneditor-schema-error .jsoneditor-popover.jsoneditor-right {
    top: -7px;
    left: 32px
  }

  .jsoneditor-schema-error .jsoneditor-popover:before {
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    content: '';
    display: block;
    left: 50%;
    margin-left: -7px;
    position: absolute
  }

  .jsoneditor-schema-error .jsoneditor-popover.jsoneditor-above:before {
    border-top: 7px solid #4c4c4c;
    bottom: -7px
  }

  .jsoneditor-schema-error .jsoneditor-popover.jsoneditor-below:before {
    border-bottom: 7px solid #4c4c4c;
    top: -7px
  }

  .jsoneditor-schema-error .jsoneditor-popover.jsoneditor-left:before {
    border-left: 7px solid #4c4c4c;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    content: '';
    top: 19px;
    right: -14px;
    left: inherit;
    margin-left: inherit;
    margin-top: -7px;
    position: absolute
  }

  .jsoneditor-schema-error .jsoneditor-popover.jsoneditor-right:before {
    border-right: 7px solid #4c4c4c;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    content: '';
    top: 19px;
    left: -14px;
    margin-left: inherit;
    margin-top: -7px;
    position: absolute
  }

.jsoneditor-schema-error:focus .jsoneditor-popover, .jsoneditor-schema-error:hover .jsoneditor-popover {
  display: block;
  -webkit-animation: fade-in .3s linear 1,move-up .3s linear 1;
  -moz-animation: fade-in .3s linear 1,move-up .3s linear 1;
  -ms-animation: fade-in .3s linear 1,move-up .3s linear 1
}
.jsoneditor-tree > jsoneditor-value jsoneditor-array{
  display:none;
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@-moz-keyframes fade-in {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@-ms-keyframes fade-in {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

.jsoneditor .jsoneditor-text-errors {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffef8b;
  border-top: 1px solid gold
}

  .jsoneditor .jsoneditor-text-errors td {
    padding: 3px 6px;
    vertical-align: middle
  }

.jsoneditor-text-errors .jsoneditor-schema-error {
  border: none;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0 4px 0 0;
  /*background: url(../img/jsoneditor-icons.svg) -168px -48px**/
}

div.jsoneditor-contextmenu-root {
  position: relative;
  width: 0;
  height: 0
}

div.jsoneditor-contextmenu {
  position: absolute;
  box-sizing: content-box;
  z-index: 99999
}

  div.jsoneditor-contextmenu li, div.jsoneditor-contextmenu ul {
    box-sizing: content-box
  }

  div.jsoneditor-contextmenu ul {
    position: relative;
    left: 0;
    top: 0;
    width: 124px;
    background: #fff;
    border: 1px solid #d3d3d3;
    box-shadow: 2px 2px 12px rgba(128,128,128,.3);
    list-style: none;
    margin: 0;
    padding: 0
  }

    div.jsoneditor-contextmenu ul li button {
      padding: 0;
      margin: 0;
      width: 124px;
      height: 24px;
      border: none;
      cursor: pointer;
      color: #4d4d4d;
      background: 0 0;
      font-size: 10pt;
      font-family: arial,sans-serif;
      box-sizing: border-box;
      line-height: 26px;
      text-align: left
    }

      div.jsoneditor-contextmenu ul li button::-moz-focus-inner {
        padding: 0;
        border: 0
      }

      div.jsoneditor-contextmenu ul li button:focus, div.jsoneditor-contextmenu ul li button:hover {
        color: #1a1a1a;
        background-color: #f5f5f5;
        outline: 0
      }

      div.jsoneditor-contextmenu ul li button.jsoneditor-default {
        width: 92px
      }

      div.jsoneditor-contextmenu ul li button.jsoneditor-expand {
        float: right;
        width: 32px;
        height: 24px;
        border-left: 1px solid #e5e5e5
      }

  div.jsoneditor-contextmenu div.jsoneditor-icon {
    float: left;
    width: 24px;
    height: 24px;
    border: none;
    padding: 0;
    margin: 0;
    /*background-image: url(../img/jsoneditor-icons.svg)*/
  }

  div.jsoneditor-contextmenu ul li button div.jsoneditor-expand {
    float: right;
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0 4px 0 0;
    /*background: url(../img/jsoneditor-icons.svg) 0 -72px;*/
    opacity: .4
  }

  div.jsoneditor-contextmenu ul li button.jsoneditor-expand:focus div.jsoneditor-expand, div.jsoneditor-contextmenu ul li button.jsoneditor-expand:hover div.jsoneditor-expand, div.jsoneditor-contextmenu ul li button:focus div.jsoneditor-expand, div.jsoneditor-contextmenu ul li button:hover div.jsoneditor-expand, div.jsoneditor-contextmenu ul li.jsoneditor-selected div.jsoneditor-expand {
    opacity: 1
  }

  div.jsoneditor-contextmenu div.jsoneditor-separator {
    height: 0;
    border-top: 1px solid #e5e5e5;
    padding-top: 5px;
    margin-top: 5px
  }

  div.jsoneditor-contextmenu button.jsoneditor-remove > div.jsoneditor-icon {
    background-position: -24px -24px
  }

  div.jsoneditor-contextmenu button.jsoneditor-remove:focus > div.jsoneditor-icon, div.jsoneditor-contextmenu button.jsoneditor-remove:hover > div.jsoneditor-icon {
    background-position: -24px 0
  }

  div.jsoneditor-contextmenu button.jsoneditor-append > div.jsoneditor-icon {
    background-position: 0 -24px
  }

  div.jsoneditor-contextmenu button.jsoneditor-append:focus > div.jsoneditor-icon, div.jsoneditor-contextmenu button.jsoneditor-append:hover > div.jsoneditor-icon {
    background-position: 0 0
  }

  div.jsoneditor-contextmenu button.jsoneditor-insert > div.jsoneditor-icon {
    background-position: 0 -24px
  }

  div.jsoneditor-contextmenu button.jsoneditor-insert:focus > div.jsoneditor-icon, div.jsoneditor-contextmenu button.jsoneditor-insert:hover > div.jsoneditor-icon {
    background-position: 0 0
  }

  div.jsoneditor-contextmenu button.jsoneditor-duplicate > div.jsoneditor-icon {
    background-position: -48px -24px
  }

  div.jsoneditor-contextmenu button.jsoneditor-duplicate:focus > div.jsoneditor-icon, div.jsoneditor-contextmenu button.jsoneditor-duplicate:hover > div.jsoneditor-icon {
    background-position: -48px 0
  }

  div.jsoneditor-contextmenu button.jsoneditor-sort-asc > div.jsoneditor-icon {
    background-position: -168px -24px
  }

  div.jsoneditor-contextmenu button.jsoneditor-sort-asc:focus > div.jsoneditor-icon, div.jsoneditor-contextmenu button.jsoneditor-sort-asc:hover > div.jsoneditor-icon {
    background-position: -168px 0
  }

  div.jsoneditor-contextmenu button.jsoneditor-sort-desc > div.jsoneditor-icon {
    background-position: -192px -24px
  }

  div.jsoneditor-contextmenu button.jsoneditor-sort-desc:focus > div.jsoneditor-icon, div.jsoneditor-contextmenu button.jsoneditor-sort-desc:hover > div.jsoneditor-icon {
    background-position: -192px 0
  }

  div.jsoneditor-contextmenu ul li button.jsoneditor-selected, div.jsoneditor-contextmenu ul li button.jsoneditor-selected:focus, div.jsoneditor-contextmenu ul li button.jsoneditor-selected:hover {
    color: #fff;
    background-color: #ee422e
  }

  div.jsoneditor-contextmenu ul li {
    overflow: hidden
  }

    div.jsoneditor-contextmenu ul li ul {
      display: none;
      position: relative;
      left: -10px;
      top: 0;
      border: none;
      box-shadow: inset 0 0 10px rgba(128,128,128,.5);
      padding: 0 10px;
      -webkit-transition: all .3s ease-out;
      -moz-transition: all .3s ease-out;
      -o-transition: all .3s ease-out;
      transition: all .3s ease-out
    }

      div.jsoneditor-contextmenu ul li ul li button {
        padding-left: 24px;
        animation: all ease-in-out 1s
      }

        div.jsoneditor-contextmenu ul li ul li button:focus, div.jsoneditor-contextmenu ul li ul li button:hover {
          background-color: #f5f5f5
        }

  div.jsoneditor-contextmenu button.jsoneditor-type-string > div.jsoneditor-icon {
    background-position: -144px -24px
  }

  div.jsoneditor-contextmenu button.jsoneditor-type-string.jsoneditor-selected > div.jsoneditor-icon, div.jsoneditor-contextmenu button.jsoneditor-type-string:focus > div.jsoneditor-icon, div.jsoneditor-contextmenu button.jsoneditor-type-string:hover > div.jsoneditor-icon {
    background-position: -144px 0
  }

  div.jsoneditor-contextmenu button.jsoneditor-type-auto > div.jsoneditor-icon {
    background-position: -120px -24px
  }

  div.jsoneditor-contextmenu button.jsoneditor-type-auto.jsoneditor-selected > div.jsoneditor-icon, div.jsoneditor-contextmenu button.jsoneditor-type-auto:focus > div.jsoneditor-icon, div.jsoneditor-contextmenu button.jsoneditor-type-auto:hover > div.jsoneditor-icon {
    background-position: -120px 0
  }

  div.jsoneditor-contextmenu button.jsoneditor-type-object > div.jsoneditor-icon {
    background-position: -72px -24px
  }

  div.jsoneditor-contextmenu button.jsoneditor-type-object.jsoneditor-selected > div.jsoneditor-icon, div.jsoneditor-contextmenu button.jsoneditor-type-object:focus > div.jsoneditor-icon, div.jsoneditor-contextmenu button.jsoneditor-type-object:hover > div.jsoneditor-icon {
    background-position: -72px 0
  }

  div.jsoneditor-contextmenu button.jsoneditor-type-array > div.jsoneditor-icon {
    background-position: -96px -24px
  }

  div.jsoneditor-contextmenu button.jsoneditor-type-array.jsoneditor-selected > div.jsoneditor-icon, div.jsoneditor-contextmenu button.jsoneditor-type-array:focus > div.jsoneditor-icon, div.jsoneditor-contextmenu button.jsoneditor-type-array:hover > div.jsoneditor-icon {
    background-position: -96px 0
  }

  div.jsoneditor-contextmenu button.jsoneditor-type-modes > div.jsoneditor-icon {
    background-image: none;
    width: 6px
  }

div.jsoneditor-menu {
  width: 100%;
  height: 35px;
  padding: 2px;
  margin: 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff;
  /*background-color: #3883fa;
  border-bottom: 1px solid #3883fa;*/
}

  div.jsoneditor-menu > button, div.jsoneditor-menu > div.jsoneditor-modes > button {
    width: 26px;
    height: 26px;
    margin: 2px;
    padding: 0;
    border-radius: 2px;
    border: 1px solid transparent;
    /*background: transparent url(../img/jsoneditor-icons.svg);*/
    color: #fff;
    opacity: .8;
    font-family: arial,sans-serif;
    font-size: 10pt;
    float: left;
  }

    div.jsoneditor-menu > button:hover, div.jsoneditor-menu > div.jsoneditor-modes > button:hover {
      background-color: rgba(255,255,255,.2);
      border: 1px solid rgba(255,255,255,.4)
    }

    div.jsoneditor-menu > button:active, div.jsoneditor-menu > button:focus, div.jsoneditor-menu > div.jsoneditor-modes > button:active, div.jsoneditor-menu > div.jsoneditor-modes > button:focus {
      background-color: rgba(255,255,255,.3)
    }

    div.jsoneditor-menu > button:disabled, div.jsoneditor-menu > div.jsoneditor-modes > button:disabled {
      opacity: .5
    }

    div.jsoneditor-menu > button.jsoneditor-collapse-all {
      /*background-position: 0 -96px*/
      background-color: #6c757d;
      width: 15%;
    }
    div.jsoneditor-menu > button.jsoneditor-collapse-all::before{
      content:'Collapse All';
    }

    div.jsoneditor-menu > button.jsoneditor-expand-all {
      /*background-position: 0 -120px*/
      background-color: #28a745;
      width : 15%;
    }
    div.jsoneditor-menu > button.jsoneditor-expand-all::before{
      content:'Expand All';
    }

    div.jsoneditor-menu > button.jsoneditor-undo {
      /*background-position: -24px -96px*/
      display: none;
    }

      div.jsoneditor-menu > button.jsoneditor-undo:disabled {
        /*background-position: -24px -120px*/
        display: none;
      }

    div.jsoneditor-menu > button.jsoneditor-redo {
      /*background-position: -48px -96px*/
      display: none;
    }

      div.jsoneditor-menu > button.jsoneditor-redo:disabled {
        /*background-position: -48px -120px*/
        display: none;
      }

    div.jsoneditor-menu > button.jsoneditor-compact {
      background-position: -72px -96px
    }

    div.jsoneditor-menu > button.jsoneditor-format {
      background-position: -72px -120px
    }

  div.jsoneditor-menu > div.jsoneditor-modes {
    /*display: inline-block;
    float: left*/
    display:none;
  }

    div.jsoneditor-menu > div.jsoneditor-modes > button {
      background-image: none;
      width: auto;
      padding-left: 6px;
      padding-right: 6px
    }

      div.jsoneditor-menu > button.jsoneditor-separator, div.jsoneditor-menu > div.jsoneditor-modes > button.jsoneditor-separator {
        /*margin-left: 10px*/
      }

  div.jsoneditor-menu a {
    font-family: arial,sans-serif;
    font-size: 10pt;
    color: #fff;
    opacity: .8;
    vertical-align: middle
  }

    div.jsoneditor-menu a:hover {
      opacity: 1
    }

    div.jsoneditor-menu a.jsoneditor-poweredBy {
      /*font-size: 8pt;
      position: absolute;
      right: 0;
      top: 0;
      padding: 10px*/
      display: none;
    }

table.jsoneditor-search div.jsoneditor-results, table.jsoneditor-search input {
  /*font-family: arial,sans-serif;
  font-size: 10pt;
  color: #1a1a1a;
  background: 0 0*/
  display: none;
}

table.jsoneditor-search div.jsoneditor-results {
  /*color: #fff;
  padding-right: 5px;
  line-height: 24px*/
  display: none;
}

table.jsoneditor-search {
  /*position: absolute;
  right: 4px;
  top: 4px;
  border-collapse: collapse;
  border-spacing: 0*/
  display: none;
}

  table.jsoneditor-search div.jsoneditor-frame {
    /*border: 1px solid transparent;
    background-color: #fff;
    padding: 0 2px;
    margin: 0*/
    display: none;
  }

    table.jsoneditor-search div.jsoneditor-frame table {
      /*border-collapse: collapse*/
      display: none;
    }

  table.jsoneditor-search input {
    /*width: 120px;
    border: none;
    outline: 0;
    margin: 1px;
    line-height: 20px*/
    display: none;
  }

  table.jsoneditor-search button {
    /*width: 16px;
    height: 24px;
    padding: 0;
    margin: 0;
    border: none;
    background: url(../img/jsoneditor-icons.svg);
    vertical-align: top*/
      display: none;
  }

    table.jsoneditor-search button:hover {
      /*background-color: transparent;*/
        display: none;
    }

    table.jsoneditor-search button.jsoneditor-refresh {
      /*width: 18px;
      background-position: -99px -73px*/
      display: none;
    }

    table.jsoneditor-search button.jsoneditor-next {
      /*cursor: pointer;
      background-position: -124px -73px*/
      display: none;
    }

      table.jsoneditor-search button.jsoneditor-next:hover {
        /*background-position: -124px -49px*/
        display: none;
      }

    table.jsoneditor-search button.jsoneditor-previous {
      /*cursor: pointer;
      background-position: -148px -73px;
      margin-right: 2px*/
      display: none;
    }

      table.jsoneditor-search button.jsoneditor-previous:hover {
        /*background-position: -148px -49px*/
        display: none;
      }

div.jsoneditor div.autocomplete.dropdown {
  /*position: absolute;
  background: #fff;
  box-shadow: 2px 2px 12px rgba(128,128,128,.3);
  border: 1px solid #d3d3d3;
  z-index: 100;
  overflow-x: hidden;
  overflow-y: auto;
  cursor: default;
  margin: 0;
  padding-left: 2pt;
  padding-right: 5pt;
  text-align: left;
  outline: 0;
  font-family: droid sans mono,consolas,monospace,courier new,courier,sans-serif;
  font-size: 10pt*/
  display: none;
}

  div.jsoneditor div.autocomplete.dropdown .item {
    /*color: #333*/
    display: none;
  }

    div.jsoneditor div.autocomplete.dropdown .item.hover {
      /*background-color: #ddd*/
      display: none;
    }

div.jsoneditor div.autocomplete.hint {
  /*color: #aaa;
  top: 4px;
  left: 4px*/
  display: none;
}

div.jsoneditor-outer {
  position: static;
  width: 100%;
  height: 500px;
  margin: -35px 0 0 0;
  padding: 35px 0 0 0;
  box-sizing: border-box;
  /*display: none;*/
}

.jsoneditor-contextmenu-button {
  display: none;
}
.jsoneditor-dragarea{
  display:none;
}
