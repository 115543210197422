@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css");

.hmrc-top-header {
  background: #00ABBC;
}

.table-container {
  background-color: #E6E7E8;
  border: 1px solid #00968878;
}
.card-auto-scroll {
  max-height: 600px;
  overflow-y: auto;
}
label {
  font-size: 15px;
}

.action-checkBox {
  display: inline-block;
  margin-left: 10px;
}

.cus-modal-header {
  height: 53px;
}

  .cus-modal-header button {
    margin-top: -10px !important;
  }

.cus-lbl-height {
  height: 37px;
}

.toggle-spinner {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 240px;
  z-index: 99;
  top: calc(100vh - 65vh);
}

/*all textarea should not reach max-height 250px this edit by Arun.arla@acuitysoft.com*/
textarea{
    min-height:150px !important;
    height:250px !important;
    max-height:250px !important;
}

/*custom scrollbar*/
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb {
  background: #2f2f2f;
  border-radius: 0px;
}

  ::-webkit-scrollbar-thumb:hover {
    background: #17a2b8;
  }

.height-auto {
  height: auto !important;
}

.mat-horizontal-stepper-header {
  display: flex;
  height: 26px !important;
  overflow: hidden;
  align-items: center;
  padding: 0 24px;
}


.partnership-select-row {
  background-color: #006FC7 !important;
  color:#fff !important;
}

/*.badge > i:before {
  float: right !important;
  font-family: FontAwesome;
  content: "+";
  padding-right: 5px;
}

.badge > i.collapsed:after {
  float: right !important;
  content: "-";
}*/
.icon-Plus {
  content: "+";
}

.icon-Minus {
  content: "-";
}

.reports-viewall-btn {
  margin-top: 6px !important;
  padding-top: 4px !important;
}

.attachment-upload-btn {
  border: 1px solid darkgray !important;
  border-radius: 5px !important;
  width: 100% !important;
}

.modal.modal-fullscreen .modal-dialog {
  max-width: 100vw !important;
  max-height: -webkit-fill-available!important;
  margin: 0;
  padding: 0;
  max-width: none;
}

.modal.modal-fullscreen .modal-content {
  height: auto;
  height: 100vh;
  border-radius: 0;
  border: none;
}

.modal.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-maximize {
  max-width: 100vw !important;
  min-height: -webkit-fill-available !important;
  margin: 0 !important;
}

.action-miniMize {
  font-size: 14px !important;
  margin-top: -7px !important;
}

.dropdown-toggle::after {
  color: #fff;
}

.mat-horizontal-stepper-header-container {
  width: max-content;
}

.mat-calendar-table-header tr th{
  border:none !important;
}

.header-font-size {
  font-size: 20px;
}

.no-wrap{
  white-space:nowrap;
}

.label-h-auto {
  height: auto !important;
}

.sa800-main-hr {
  height: 0.1px !important;
}
/*input[type="text"]:focus {
  text-align: left;
}

input[type="text"] {
  text-align: right;
}*/
.layout-show-hide {
  padding-left: 0px !important;
}

.sidenav-show-hide {
  display: none;
}

.text-error {
  color: red;
}

.text-green {
  color: green !important;
}

.sa800-main-question {
  background-color: #406FA2 !important;
  width: 80px;
  /*border-radius: 0.25rem;*/
}

  .sa800-main-question .input-group-text {
    background-color: #406FA2 !important;
    margin-left: auto;
  }

.sa800-main-inner-box {
  padding: 0px 5px 0px 15px;
  background-color: #400040;
  content: '&nbsp';
  margin-right: 10px;
  border-radius: 2px;
  display: inline-block;
  height: 11px;
  width: 10px;
}

  .sa800-main-inner-box span {
    margin-bottom: 5px;
  }

.title-heading-box {
  padding: 0px 5px 0px 15px;
  background: #6FA8AA;
  content: '&nbsp';
  margin-right: 10px;
  border-radius: 2px;
}

/*table start*/
table {
  width: 100%;
  border-collapse: collapse;
}

td {
  font-weight: 400 !important;
}

/* Zebra striping */
/*tr:nth-of-type(odd) {
  background: #C5DCFA;
}*/

tr:nth-of-type(even) {
  background: #fff;
}

.table-custom {
  background-color: #f2f2f2 !important;
}

  .table-custom tbody tr td {
    padding: 6px 6px 0px 6px !important;
  }

  .table-custom tr:nth-of-type(even) {
    background-color: #f2f2f2 !important;
  }

th {
  background: #fff;
  color: #000;
  font-weight: 300;
}

/*td, #212529*/
th {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
}

span {
  font-style: normal !important;
}

/*06/10/2020 css start*/
.cus-chkbox-left-align {
  position: relative;
  left: 60px;
}

/*td {
  font-family: 'Roboto', sans-serif !important;
  font-weight: 300;
  line-height: 14px;
  padding: 10px 0 8px 10px;
}*/
.table th, .table td {
  padding: 0.5rem !important;
  vertical-align: top;
  border-top: 1px solid #000000;
}

.table-hover tbody tr:hover td {
  background-color: #d6f3ee;
  color: #000;
  /* color: #fff;*/
}

/*#tpTableGrid tbody tr:hover td {
  background-color: #a9cad0;
  color: #fff;
    background-color: C1DAD6
}*/

.tbl-header-b-bottom {
  border-bottom: 2.4px solid #000;
}

.table-bordered {
  border: 1px solid #000 !important;
}

.dashboard-modal-bg {
  background: #5ac4ce;
}

.font-12{
    font-size:12px !important;
}

.color-find-what {
  color: #212529 !important;
  font-size: 14px !important;
  font-weight:normal;
}

input[type=checkbox] {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  padding: 15px;
  border: none !important;
}

#fbi-receipt-table tr td {
  width: 150px;
  border-top: none;
  padding: 0 !important;
} 
/*checkbox start*/
input.sa800chkbox {
  position: absolute;
  opacity: 0;
}

  input.sa800chkbox:checked + label svg path {
    stroke-dashoffset: 0;
  }

  input.sa800chkbox:focus + label {
    transform: scale(1.03);
  }

.sa800chkbox + label {
  display: block;
  border: 1px solid #bce2e0;
  text-align: center;
  padding: 3px 4px 0px 0px;
  width: 40px;
  height: 36px;
  cursor: pointer;
  transition: all 0.2s ease;
  background: #fff;
}

  .sa800chkbox + label:active {
    transform: scale(1.05);
    border-radius: 0px;
  }

  .sa800chkbox + label svg {
    pointer-events: none;
    height: 23px;
    padding-left: 4px;
  }

    .sa800chkbox + label svg path {
      fill: none;
      stroke: green;
      stroke-width: 4px;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-dasharray: 100;
      stroke-dashoffset: 101;
      transition: all 350ms cubic-bezier(1, 0, 0.37, 0.91);
    }
/*checkbox end*/

/*custom-checkbox for dashboard start*/
.sa800chk-Container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 0px;
  cursor: pointer;
  line-height: 17px;
  font-size: 14px;
}

  /* Hide the default checkbox */
  .sa800chk-Container input[type=checkbox] {
    visibility: hidden;
  }

/* Creating a custom checkbox
        based on demand */
.sa800chkBox {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 0.5px solid #2f2f2f;
  border-radius: 3px;
}

/* Specify the background color to be
        shown when hovering over checkbox */
.sa800chk-Container:hover input ~ .sa800chkBox {
  background-color: #fff;
  border: 0.5px solid #2f2f2f;
}

/* Specify the background color to be
        shown when checkbox is active */
.sa800chk-Container input:active ~ .sa800chkBox {
  background-color: #fff;
}

/* Specify the background color to be
        shown when checkbox is checked */
.sa800chk-Container input:checked ~ .sa800chkBox {
  background-color: #fff;
}

/* Checkmark to be shown in checkbox */
/* It is not be shown when not checked */
.sa800chkBox:after {
  content: "";
  position: absolute;
  display: none;
}

/* Display checkmark when checked */
.sa800chk-Container input:checked ~ .sa800chkBox:after {
  display: block;
}

/* Styling the checkmark using webkit */
/* Rotated the rectangle by 40 degree and 
        showing only two border to make it look
        like a tickmark */
.sa800chk-Container .sa800chkBox:after {
  left: 6px;
  bottom: 4px;
  width: 6px;
  height: 12px;
  border: solid green;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
}
/*custom-checkbox for dashboard end*/

.fbi-receipt-long-text {
  position: absolute;
  display: inline;
  top: -8px;
}

.fbi-receipt-2nd-td {
  position: relative;
  top: 7px;
}

.swal2-popup {
  width: 25% !important;
}

input[type="checkbox"]:disabled + label {
  background: #e9ecef !important;
  border: 1px solid #bce2e0;
}

/*@media only screen and (min-width:767px){
  .sb-nav-fixed #layoutSidenav #layoutSidenav_content {
    padding-left: 0px !important;
    top: 56px;
  }
}*/

.badge > a:before {
  float: right !important;
  font-family: FontAwesome;
  content: "\f068";
  padding-right: 5px;
}

@media only screen and (max-width: 991px) {
  .swal2-popup {
    width: unset !important;
  }
}

@media only screen and (max-width: 923px) {
  .modal-open .modal {
    z-index: 9999999999;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .height-750 {
    height: 885px !important;
  }

  .embed-responsive-21by9 {
    height: 810px !important;
  }
}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  table .responsive, .responsive thead, .responsive tbody, .responsive th, .responsive td, .responsive tr {
    display: block !important;
  }

    /* Hide table headers (but not display: none;, for accessibility) */
    .responsive thead tr {
      position: absolute !important;
      top: -9999px;
      left: -9999px;
    }

  .responsive tr {
    border: 1px solid #ccc;
  }

  .responsive td {
    border: none;
    border-bottom: 1px solid #eee !important;
    position: relative !important;
    padding-left: 35% !important;
    font-size:15px;
  }

    .responsive td:before {
      position: absolute !important;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap !important;
      font-weight: 500 !important;
    }

  td {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 300;
    padding: 14px;
    line-height: 12px;
  }

  .responsive td:before {
    content: attr(data-table-header) !important;
  }

  .fbi-receipt-long-text {
    position: absolute;
    display: inline;
    top: -8px;
    line-height: 21px;
  }

  .height-750 {
    height: 520px !important;
  }
}



@media only screen and (min-width:800px){
  /*#layoutSidenav #layoutSidenav_nav {
    transform: translateX(-270px) !important;
  }*/

  .layout-show-hide {
    padding-left: 0px !important;
  }

  .sidenav-show-hide {
    display: none;
  }
}

@media only screen and (min-width: 320px) and (max-device-width: 630px) {

  .responsive td {
    border: none;
    border-bottom: 1px solid #eee !important;
    position: relative !important;
    padding-left: 59% !important;
    font-size:12px;
  }

  .btn-top-gapp {
    margin-top: 2em;
    margin-left: 0;
  }

  .mobile-dropdown-menu{
    display:block;
  }

  .lg-dropdown-menu {
    display: none;
  }

  textarea {
    height: 150px !important;
    max-height: 250px !important;
  }
}

@media only screen and (max-width:589px){
  #fbi-receipt-table tr td:nth-child(1) label{
    width: max-content !important;
  }

  .fbi-receipt-long-text {
    position: absolute;
    display: inline;
    top: -6px;
    width: max-content;
  }

  .fbi-receipt-2nd-td{
      position:relative;
      top:7px;
  }
}

@media only screen and (max-width:500px) {
  .header-bg {
    background-color: #4AB7C1;
    border-top: 8px solid #fd7719;
    height: max-content;
  }

  .main-top {
    margin-top:0px;
  }

  .HeaderName{
      display:none;
  }
}

/*table end*/
.prepend-blue-bg {
  background-color: #000080;
  border-radius: 0.25rem;
  height: 35.6px;
}
.bg-foreign-form {
  background: #ABA000;
}
.bg-sky {
  background: #03a9f4;
}
.foreign-form-right-border {
  border-right: 55px solid #ABA000;
}

.foreign-form-left-border {
  border-left: 30px solid #ABA000;
}

.show-tick-Mark {
  content: "\2713" !important;
  color: red !important;
  background-color: gray;
}

.hide-tick-Mark {
}

.foregin-note {
  color: green;
  font-weight: 300;
}

.fa-edit {
  color: #16ab04;
  font-size: 16px;
}

.fa-trash {
  color: #CD0000;
  font-size: 16px;
  margin-left: 10px;
}

.box-inline {
  display: flex;
  /* flex-flow: row wrap; */
  align-items: center;
}

.g-form-left-border {
  border-left: 104px solid #11b7ca;
}

.on-focus-text-align input[type=text]:focus {
  text-align: left;
}

.on-focus-text-align input[type=text] {
  text-align: right;
}

.sa800-icon {
  padding: 0px;
  padding-right: 7px;
  margin-top: -1px;
}

.box-inline {
  display: flex;
  /* flex-flow: row wrap; */
  align-items: center;
}

.height-check-input {
  height: 36px;
}
.input-group-prepend ~ .input-group-append .mat-icon-button,
.prepend-blue-bg ~ .input-group-append .mat-icon-button {
  padding: 0;
  min-width: 0;
  width: 40px;
  height: 25px !important;
  flex-shrink: 0;
  line-height: 25px !important;
  border-radius: 50%;
}

.mat-calendar-controls{
    margin:10px !important;
}

.swal2-styled.swal2-confirm {
  background-color: #12abb9 !important;
}
  .swal2-styled.swal2-confirm:focus {
    box-shadow: 0 0 0 3px rgb(112 210 220)!important;
  }


.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgb(74 183 193 / 28%)!important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgb(74 183 193);
}

.action-btn-sm {
  display: inline-flex !important;
}
div.jsoneditor-tree {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: unset!important;
}


/*Gautam add FBI css Responsive*/
@media only screen and (min-width:992px) and (max-width:1199px) {
  button.jsoneditor-expand-all {
    width: 40%!important;
  }
  button.jsoneditor-collapse-all {
    width: 40%!important;
  }
}

  @media only screen and (max-width: 768px) {
    body {
      font-size: 0.9rem !important;
    }

    div.jsoneditor-menu > button.jsoneditor-expand-all {
      background-position: 0 -120px;
      background-color: #28a745;
      width: 46% !important;
    }

    div.jsoneditor-menu > button.jsoneditor-collapse-all {
      background-position: 0 -96px;
      background-color: #6c757d;
      width: 46% !important;
    }
  }





  @media only screen and (max-width: 600px) {


    .mat-toolbar-row, .mat-toolbar-single-row {
      display: flex;
      box-sizing: border-box;
      padding: 0 16px;
      width: 100%;
      flex-direction: row;
      align-items: center;
      white-space: nowrap;
      height: 100px;
    }
  }

  @media (max-width: 330px) {

    button.btn.sm-btn-size {
      padding: 4px;
      margin: 0px;
    }

    .fbi_tree, ul {
      list-style: none;
      margin: 0;
      padding: 0px 1px !important;
    }
  }
  /*.mat-list-base .mat-list-item, .mat-list-base .mat-list-option {
  display: block;
  height: 31px!important;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  padding: 0;
}
*/
.swal2-html-container {
  color: #000000 !important;
}

.tooltiptext {
  position: absolute;
  background: black;
  color: white;
  top: -40px;
  width: auto;
  padding: 5px 10px;
  border-radius: 0px !important;
  white-space: pre;
  z-index: 999;
}

  .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

/*.col-lg-12 .tooltiptext {
  width: 60%;
  position: absolute;
  margin: auto;
  height: auto;
  top: -100%;
  z-index: 9999;
}
*/
/*.datatooltip {
  top: 0;
  position: absolute;
  left: 100%;
  width: 200px;
  z-index: 999;
}*/
.datatooltip {
  top: 40px;
  position: absolute;
  width: auto;
  z-index: 999;
}

  .datatooltip::after {
    top: -10px !important;
    left: 25px !important;
    transform: rotate(180deg);
  }

/*  .datatooltip::after {
    content: "";
    position: absolute;
    top: 9px !important;
    left: -5px !important;
    transform: rotate(90deg);
  }*/
.customtooltip .datatooltip {
}

.input-group-prepend ~ .datatooltip {
}

@media screen and (min-width:800px) and (max-width:1132px) {
  .tooltiptext {
    top: -40px !important;
    white-space: pre;
  }
  .swal2-popup {
    width: 60% !important;
  }
  .datatooltip {
    top: 44px !important;
    left: 0px !important;
  }

  .input-group-prepend ~ .datatooltip {
    top: 45px !important;
    left: 0px !important;
  }

  .datatooltip::after {
    content: "";
    position: absolute;
    top: -10px !important;
    left: 33px !important;
    transform: rotate(180deg);
  }
}


@media screen and (min-width:601px) and (max-width: 799px) {
  .sm-company .color-blue {
    font-size: 12px;
  }


  .sm-company b {
    font-size: 13px;
  }

  .tooltiptext {
    top: -40px;
  }


  .datatooltip {
    left: 0px !important;
    top: 45px !important;
  }


  .input-group-prepend ~ .datatooltip {
    left: 0 !important;
    top: 45px !important;
  }

  .datatooltip::after {
    content: "";
    position: absolute;
    top: -10px !important;
    left: 33px !important;
    transform: rotate(180deg);
  }
}

@media screen and (max-width: 600px) {
  .datatooltip {
    left: 0px !important;
    top: 45px !important;
  }

    .datatooltip::after {
      content: "";
      position: absolute;
      top: -10px !important;
      left: 33px !important;
      transform: rotate(180deg);
    }

  .input-group-prepend ~ .tooltiptext {
    top: -40px !important;
  }

  .tooltiptext {
    top: -40px;
  }


  .input-group-prepend ~ .datatooltip {
    left: 0 !important;
    top: 45px !important;
  }
}


button{
    margin:5px  !important;
}

#containerpadding  .mat-horizontal-stepper-header {
  padding: 0 14px !important;
}
#containerpadding .mat-horizontal-content-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
#Importdatamodal .modal-dialog,
#Formdataimportweb .modal-dialog {

  max-width: 700px !important
}


@media screen (max-width:700px) {
  #Importdatamodal .modal-dialog,
  #Formdataimportweb .modal-dialog {
    width: 100%;
    max-width: 100% !important
  }
}

#Importdatamodal input[type="checkbox"]{
    width:20px;
    height:20px;
}

/*.modal-header {
  background: #4AB7C1 !important;
  color: white;

}*/
.modal-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: aliceblue;
}

/*.modal-body {
  background: #f1f1f1 !important;
}*/
::-webkit-scrollbar{
    width:10px !important;
    height:10px;
}
#DetailsPopup1 .modal-header {
 
  color: white !important;
}

#DetailsPopup1 .text-header {
  background: rgba(0,0,0,.05) !important;
  padding: 8px;
  margin: 0px;
}

input:enabled ~ .custom-file-label::after {
  background: #3085d6;
  color: white;
}


.supplyform ul, supplyform li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.supplyform ul {
  padding-left: 1em;
}

.supplyform .border {
  background: white;
}

.supplyform li {
  padding-left: 1em;
  border: 1px dotted black;
  border-width: 0 0 1px 1px;
}

  .supplyform li.empty {
    font-style: italic;
    color: silver;
    border-color: silver;
  }

  .supplyform li label {
    margin: 0;
    position: relative;
    top: 1.1em;
    width: 100%;
    background: white;
    margin-top: 14px;
  }

.iconbox {
  border: 2px solid gray;
  padding: 2px;
  margin-left: 2px;
  width: 25px;
  height: 25px;
  display: inline-block;
  text-align: center;
  line-height: 14px;
  padding-left: 5px;
}

.fa-plus, .fa-minus {
  font-family: FontAwesome;
  font-style: initial;
}
.dropmenu {
  border: none !important;
  margin-top:5px;
}

.deselect {
  background: #fffbfb;
  padding: 0px !important;
  width: 120px;
  font-size: 15px;
  text-align: left;
  font-weight: bold;
  position: absolute;
  right: 16px;
  z-index: 999;
  margin-top: -5px !important;
  border: 1px solid #fffbfb;
}
  .deselect li {
    border: none;
    padding: 8px 11px;
    background: #f7f7f7;
    border-bottom: 1px dotted #706f6f;
  }
    .deselect li:hover{
        background:#ccc;
    }
    .text-display-custom {
      font-size: 15px;
      margin: 0px;
      position: relative;
      top: -5px;
      left: 6px;
    }
.customdate .mat-button-wrapper {
  transform: scale(0.9);
  position: relative;
  top: -5px;
}
li {
  cursor: pointer;
}

.blue {
  color: #0400ff;
}

.grey {
  color: black;
}
@media screen and (min-width:768px) {
  .swal2-title {
    display: block !important;
    position: absolute !important;
    font-size: 16px !important;
    background: #f1f1f1;
    width: 100%;
    text-align: left !important;
    margin-bottom: 25px;
    padding-bottom: 7px !important;
    top: 0px;
    left: 0px;
  }

  .swal2-container.swal2-center > .swal2-popup {
    display: block !important;
    border-radius: 0px !important;
    padding: 10px !important;
    overflow: hidden;
  }

  .swal2-icon {
    display: inline-block;
    float: left;
    transform: scale(0.5);
    margin: 25px auto .6em !important;
  }

    .swal2-icon.swal2-question, .swal2-icon.swal2-info, .swal2-icon.swal2-warning {
      background: #0078d7 !important;
      color: white !important;
      border: 1px solid #0078E7;
    }

  .swal2-actions button {
    font-size: 14px !important;
    padding: 5px 29px !important;
  }

  .swal2-html-container {
    display: inline-block !important;
    position: relative;
    margin: 19px auto 0px !important;
    float: right;
    font-size: 16px !important;
    height: auto !important;
    text-align: left !important;
    width: 80%;
    margin: 0px !important;
    font-weight: 400 !important;
    padding: 25px 0px !important;
    color: black !important;
  }

  .swal2-actions {
    justify-content: end !important;
    border-top: 1px solid #ebe7e7;
    padding-top: 5px !important;
  }

  .swal2-icon-question {
    width: 390px !important;
  }
  .swal2-icon-error .swal2-html-container {
    margin-top: 16px !important;
  }
    .swal2-icon-question .swal2-html-container {
      width: 76% !important;
      margin: 25px auto 0px !important;
    }

  .swal2-icon {
    margin: 25px auto 0px !important;
  }

  .swal2-icon-info {
    width: 455px !important;
  }

    .swal2-icon-info .swal2-html-container {
      width: 79% !important;
      margin: 25px auto 0px !important;
    }

  .swal2-icon-warning {
    width: 420px !important;
  }

    .swal2-icon-warning .swal2-html-container {
      width: 79% !important;
      margin: 25px auto 0px !important;
    }
  .swal2-icon-success .swal2-html-container {
    margin: 25px auto 0px !important;
  }
}
th, td {
    text-align: center !important;
}

.mat-sort-header-container {
    display: inline-flex !important;
}

app-dashboard .sa800chkBox {
    top: 11px !important;
}

app-status .fa-minus, app-status .fa-plus {
    width: 20px !important;
    height: 20px !important;
    border: 1px solid #000;
    padding: 3px;
}

.body-scrol-m:has(.modal-open) {
    overflow: hidden !important;
}
.custom-file-label {
   overflow: hidden !important;
}

.is-invalid ~ .iconselect {
  display: none;
}

.iconselect {
  position: absolute;
  right: 25px;
  top: 13px;
  font-size: 11px;
  z-index: 99 !important;
}

.dropdown-menulist {
  display: block !important;
  box-shadow: none !important;
  border: 1px solid #d1cdcd;
  margin-top: 0;
  animation: none;
  padding: 5px 0px !important;
  font-size: 0.875rem;
  color: #495057;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  position: absolute;
  z-index: 1000;
  width: calc(100% - 24px);
}

.border-radius-5 {
  border-radius: 5px !important;
}

.dropdown-itemlist {
  line-height: 13px;
  display: block;
  width: 100%;
  padding: 0.35rem 1.25rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

  .dropdown-itemlist:hover {
    background: #1e90ff !important;
    color: white !important;
  }

.activeSearchLink {
  background: #1e90ff;
  color: white !important;
}

.dropdown-menulist:hover .activeSearchLink {
  color: gray !important;
  background: transparent !important;
}

  .dropdown-menulist:hover .activeSearchLink:hover {
    background: #1e90ff !important;
    color: white !important;
  }
.hidepopup {
  display: none !important; 
}

#popuptax .form-group
{
  margin-bottom: 5px !important;
}
.btncustom {
  /* help button unable to dispaly please remove display none */
 /* display: none !important;*/
}


#taxadv .tax-adviser-group-container {
  border: 1px solid #bce2e0 !important;
}




#taxadv .tab-content {
  background: white;
  border: 1px solid rgba(128,128,128,.3215686274509804);
  padding: 12px;
}



#taxadv #select-tab {
  color: #495057;
  background-color: #fff !important;
  border: 1px solid #8080805e !important;
  font-weight: 700;
  font-size: 14px;
}



#taxadv .modal-body {
  background: #f0f0f0 !important;
}



#taxadv .input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #00928f !important;
  text-align: center;
  white-space: nowrap;
  background-color: #00928f36 !important;
  border: 0 solid #ea5e00;
  border-radius: 0.25rem;
}

.mat-mdc-icon-button svg {
  vertical-align: baseline;
  position: relative;
  top: -8px;
}

.mat-datepicker-toggle {
  pointer-events: auto;
  padding: -14px;
  width: 49px;
  height: 37px;
}
.mat-mdc-icon-button {
  top: -6px;
  left: 1px;
  right: 4px;
  bottom: 16px;
  position: absolute;
}
  .mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
    top: 1px;
    left: 3px;
    right: 4px;
    bottom: 4px;
    position: absolute;
    pointer-events: none;
    border-radius: inherit;
  }
  .mat-mdc-icon-button:focus-visible {
    /* top: -7px;*/
    left: 314px;
    height: 36px;
    top: -3px;
    position: absolute;
    pointer-events: none;
  }
#tpdatetimepicker1 .mat-mdc-icon-button:focus-visible,
#tpdatetimepicker2 .mat-mdc-icon-button:focus-visible,
#tpdatetimepicker3 .mat-mdc-icon-button:focus-visible,
#tpdatetimepicker4 .mat-mdc-icon-button:focus-visible {
  left: 309px;
}
#hmrcdatetimepicker1 .mat-mdc-icon-button:focus-visible {
  left: 366px;
}
#rpdatetimepicker1 .mat-mdc-icon-button:focus-visible,
#rpdatetimepicker2 .mat-mdc-icon-button:focus-visible {
  left: 467px;
}
#rpsdatetimepicker4 .mat-mdc-icon-button:focus-visible,
#rpedatetimepicker5 .mat-mdc-icon-button:focus-visible {
  left: 359px;
}
#pcsdatetimepicker1 .mat-mdc-icon-button:focus-visible,
#pcedatetimepicker2 .mat-mdc-icon-button:focus-visible {
  left: 221px;
}
#pcs7datetimepicker1 .mat-mdc-icon-button:focus-visible,
#pce7datetimepicker2 .mat-mdc-icon-button:focus-visible {
  left: 230px;
}

.modal-content .mat-mdc-icon-button:focus-visible {
  left: 441px;
}

