.danger-form-right-border {
  border-right: 55px solid #dc3545;
}
.danger-form-left-border {
  border-left: 30px solid #dc3545;
}

.g-form-right-border {
  border-right: 55px solid #11b7ca;
}
.g-form-left-border {
  border-left: 30px solid #11b7ca;
}


.table-container {
  background-color: #E6E7E8;

}

.box-red {
/*  border: 5px solid red;*/
  background: red;
  padding: 0 15px 0px 0;
  margin-right:10px;
}
.box-green {
  /*  border: 5px solid red;*/
  background: #41929c;
  padding: 0 15px 0px 0;
  margin-right: 10px;
}
/* pound sybmble css */
.input-group .p-symbol:before {
  position: absolute;
  content: "£";
  left: 7px;
  top: 7px;
}

.p-symbol:before {
  position: absolute;
  content: "£";
  left: 7px;
  top: 8px;
}


.p-symbol {
  display: inline;
  display: block;
  position: relative;
  width: 100%;
  z-index: 9;
}

.borderless table {
  border-collapse: separate;
  border-spacing: 0 8px;
}


.input-custom-pl {
  padding-left: 22px!important;
}
/* pound sybmble css end */
.fa-trash {
  color: darkred;
}

.fa-edit {
  color: #16ab04;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff!important;
  text-align: center;
  white-space: nowrap;
  background-color: #000080!important;
  border: 0px solid #ea5e00;
  border-radius: 0.25rem;
}

.fa-edit-btn {
  color: #16ab04;
  font-size: 16px;
  margin-left: 10px;
}

.fa-delete-btn {
  color: #CD0000;
  font-size: 16px;
}

